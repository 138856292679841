body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

* {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: goldenrod;
  border-radius: 12px;
}

.main {
  /* background: rgb(255, 214, 0);
  background: linear-gradient(90deg, rgba(255, 214, 0, 1) 0%, rgba(255, 246, 207, 1) 50%, rgba(255, 214, 0, 1) 100%); */



  /* background: rgb(28, 41, 58);
  background: linear-gradient(90deg, rgba(28, 41, 58, 1) 0%, rgba(96, 152, 170, 1) 50%, rgba(0, 66, 85, 1) 100%); */


  background: rgb(28, 41, 58);
  background: linear-gradient(90deg, rgba(28, 41, 58, 1) 0%, rgba(16, 84, 105, 1) 50%, rgba(0, 66, 85, 1) 100%);


  /* background: rgb(28, 41, 58);
  background: linear-gradient(90deg, rgba(28, 41, 58, 1) 0%, rgba(3, 49, 63, 1) 50%, rgba(0, 66, 85, 1) 100%); */

}

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */



















/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #272829;
}

a {
  color: #0563bb;
  text-decoration: none;
}

a:hover {
  color: #067ded;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0563bb;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #0678e3;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #0563bb;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9997;
  transition: all 0.5s;
  padding: 15px;
  overflow-y: auto;
}

@media (max-width: 991px) {
  #header {
    width: 300px;
    background: #fff;
    border-right: 1px solid #e6e9ec;
    left: -300px;
  }
}

@media (min-width: 991px) {
  #main {
    margin-left: 100px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.centerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-menu {
  padding: 0;
  display: block;
  /* border: 1px solid; */
}



.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu>ul>li {
  position: relative;
  top: 0px;
  white-space: nowrap;
}

.nav-menu a,
.nav-menu a:focus {
  display: flex;
  align-items: center;
  color: #45505b;
  padding: 10px 18px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  border-radius: 50px;
  background: rgb(221, 177, 65);
  height: 56px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}

.nav-menu a i,
.nav-menu a:focus i {
  font-size: 20px;
}

.nav-menu a span,
.nav-menu a:focus span {
  padding: 0 5px 0 7px;
  color: #45505b;
}

.navtext {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 992px) {

  .nav-menu a,
  .nav-menu a:focus {
    width: 56px;
  }

  .nav-menu a span,
  .nav-menu a:focus span {
    display: none;
    color: #fff;
  }
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu .active:focus,
.nav-menu li:hover>a {
  color: rgb(221, 177, 65);
  background: white;
}

.nav-menu a:hover span,
.nav-menu .active span,
.nav-menu .active:focus span,
.nav-menu li:hover>a span {
  color: rgb(221, 177, 65);
}

.nav-menu a:hover,
.nav-menu li:hover>a {
  width: 100%;
  color: rgb(221, 177, 65);
}

.nav-menu a:hover span,
.nav-menu li:hover>a span {
  display: block;
}

/**
* Mobile Navigation 
*/
.res-menu {
  display: none;
}

.mobile-nav-toggle {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 35px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 0;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
}

/* .mobile-nav-toggle i {
  color: #45505b;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active #header {
  left: 0;
}

.mobile-nav-active .mobile-nav-toggle {
  color: #fff;
  background-color: #0563bb;
} */

.modal {
  display: none;

}



.modal1 {
  display: block;
  z-index: 2;
  animation: modal 0.3s linear;
}

@-webkit-keyframes modal {
  from {
    width: 0%;
    height: 0%;
  }

  to {
    width: 100%;
    height: 100%;
  }
}

@keyframes modal {
  from {
    width: 0%;
    height: 0%;
  }

  to {
    width: 100%;
    height: 100%;
  }
}



.mobil-res-navbar-modal {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 60%;
  z-index: 2;
  padding-left: 1%;
  padding-right: 1%;
  cursor: pointer;
  border-right: 2px solid white;
  background-color: transparent;
  backdrop-filter: blur(15px);

}

.mobil-nev>ul>li {
  position: relative;
  top: 30vh;
  white-space: nowrap;
  padding-bottom: 2%;

}


.mobil-nev a,
.mobil-nev a:focus {
  display: flex;
  align-items: center;
  color: #45505b;
  padding: 10px 18px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  border-radius: 50px;
  background: white;
  height: 56px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}

.mobil-nev a i,
.mobil-nev a:focus i {
  font-size: 20px;
}

.mobil-nev a span,
.mobil-nev a:focus span {
  padding: 0 5px 0 7px;
  color: #45505b;
}

.mobil-nev a:hover,
.mobil-nev .active,
.mobil-nev .active:focus,
.mobil-nev li:hover>a {
  color: white;
  background: rgb(221, 177, 65);
}

.mobil-nev a:hover span,
.mobil-nev .active span,
.mobil-nev .active:focus span,
.mobil-nev li:hover>a span {
  color: white;
}

.mobil-nev a:hover,
.mobil-nev li:hover>a {
  width: 100%;
  color: white;
}





@media only screen and (max-width: 991px) {
  .res-menu {
    display: block;
    color: goldenrod;
  }


}

@media only screen and (max-width: 600px) {
  .mobile-nav-toggle {
    font-size: 30px;
  }
}




/* gallery nav btn */

.homeBTn {
  display: none;
}

@media only screen and (max-width: 991px) {

  .homeBTn {
    display: block;
    border: 1px solid;
    height: 45px;
    width: 45px;
    z-index: 9999999;
    border-radius: 30px;
    text-align: center;
    padding-top: 11px;
    background: white;
    cursor: pointer;
    position: fixed;
    top: 10px;
    left: 10px;
  }

  .bx-home {
    color: goldenrod;
  }

}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.mainDiv {
  /* height: 100vh; */
  /* border: 5px solid black; */
  /* background: url("../../public/images/assit/bannerImg.jpg"); */
  background: url("./assets/images/banner.jpeg");
  /* background-size: 100% 100%; */
  background-size: cover;
  background-repeat: no-repeat;
  /* padding-bottom: 420px; */
}

#hero {
  /* width: 100%; */
  height: 100vh;
  /* background: url("../../public/images/assit/bannerImg.jpg") top right no-repeat; */
  /* background-size: cover; */
  /* position: relative; */
}


@media (min-width: 992px) {
  #hero {
    padding-left: 160px;
  }
}

#hero:before {
  content: "";
  /* background: rgba(255, 255, 255, 0.5); */
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

/* #hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: white;
  position: relative;
  top: 90px;
} */

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: goldenrod;
  font-family: Georgia, serif;
	/* -webkit-text-stroke: 1px white; */
	text-shadow: 2px 2px 4px black;
  position: relative;
  top: 90px;
}

#hero p {
  color: white;
  margin: 15px 0 0 0;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  position: relative;
  top: 80px;
}

#hero p span {
  color: rgb(221, 177, 65);
  letter-spacing: 1px;

}

#hero .social-links {
  margin-top: 30px;
}

#hero .social-links a {
  font-size: 24px;
  display: inline-block;
  color: white;
  line-height: 1;
  margin-right: 20px;
  transition: 0.3s;
}

#hero .social-links a:hover {
  color: rgb(221, 177, 65);
}

@media (max-width: 992px) {
  #hero {
    text-align: center;
  }

  #hero h1 {
    font-size: 32px;
    line-height: 36px;
  }

  #hero p {
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: goldenrod;
  font-family: Georgia, serif;
	/* -webkit-text-stroke: 1px white; */
	text-shadow: 2px 2px 4px black;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: goldenrod;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
  color: white;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  /* margin-left: 5%; */
  width: 85%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
}

.about .img-fluid {
  height: 650px;
  width: 10000px;
}

.cont {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: white;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #0563bb;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Facts
--------------------------------------------------------------*/
.facts {
  width: 92%;
  margin-left: 8%;
}

.couterNum {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.facts .count-box {
  padding: 30px 30px 25px 30px;
  margin-top: 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #fff;
}

.facts .count-box i {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #0563bb;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.facts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #011426;
}

.facts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills {
  width: 85%;
  margin: 0px auto;
  /* margin-left: 8%; */
  display: flex;
  justify-content: center;
  margin-top: -50px;
  /* border: 1px solid; */
}

.skillsFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

}

.skillswith {
  width: 45%;
}

.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #45505b;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #f2f3f5;
}

.skills .progress-bar {
  width: 1%;
  height: 10px;
  transition: 0.9s;
  background-color: #0563bb;
}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume {
  width: 92%;
  margin-left: 8%;
}

.resumRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.resum1 {
  width: 46%;
}

.resume .resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #45505b;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #0563bb;
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #0563bb;
  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  background: #f7f8f9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #0563bb;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolioflex {
  width: 85%;
  /* margin-left: 8%; */
  margin: 0px auto;
  display: flex;
  justify-content: center;
  margin-top: -120px;
  /* border: 1px solid; */
}

.portfolioflexdata {
  width: 85%;
  /* margin-left: 8%; */
  margin: 0px auto;
  display: flex;
  justify-content: center;
  /* border: 1px solid; */
}

.img1 {
  width: 500px;
  height: 500px;
}

.img2 {
  width: 500px;
  height: 330px;
  /* border: 1px solid; */
}

.portfolio .portfolio-item {
  margin-bottom: 60px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: #fff;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #272829;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #0563bb;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  height: fit-content;
  z-index: 1;
  background: rgba(69, 80, 91, 0.8);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #d7dce1;
  border-left: 3px solid #d7dce1;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #d7dce1;
  border-right: 3px solid #d7dce1;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #45505b;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #45505b;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #45505b;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: goldenrod;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

@media (max-width: 992px) {
  .portfolioflex {
    width: 84%;
    margin-top: -300px;
    margin-left: 8%;
    margin-right: 8%;
  }

  
}

@media (max-width: 600px) {
  .portfolioflex {
    margin-top: -280px;
  }

  
}

@media (max-width: 450px) {
  .portfolioflex {
    margin-top: -250px;
  }

  
}

@media (max-width: 400px) {
  .portfolioflex {
    margin-top: -220px;
  }

  
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #0563bb;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #0563bb;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(69, 80, 91, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/


.servicesWith {
  width: 92%;
  margin-left: 8%;
}

.servicesFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.servicesRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;

}


.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;

}

.services .icon-box h4 a {
  color: #45505b;
  transition: ease-in-out 0.3s;
  text-decoration: none !important;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}

.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.sliderHight {
  height: auto;
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
}

.testimonials .testimonial-item .testimonial-img {
  width: 120px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #90c8fc;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #0563bb;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #0563bb;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contactWith {
  width: 85%;
  /* margin-left: 8%; */
  margin: 0px auto;
  display: flex;
  justify-content: center;
  margin-top: -50px;
  /* border: 1px solid; */
}

.contactRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}


.contactRow1 {
  width: 40%;
  margin-top: 1%;
  /* border: 1px solid; */
  background-image: url(../src/assets/images/mapImg.jpg);
  background-repeat: no-repeat;
  background-size: 90%;
}

.contactRow2 {
  width: 60%;
}

.inputMainDiv {
  display: flex;
  flex-direction: row;
}

.inputMainDiv div {
  width: 47.5%;
}

.input {
  border: 1px solid rgb(175, 172, 172);
  width: 90%;
  padding-left: 13px;
}


.contact .info {
  width: 100%;
  background: transparent;
}

.contact .info i {
  font-size: 20px;
  color: goldenrod;
  float: left;
  width: 44px;
  height: 44px;
  background: #eef7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: white;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: white;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: goldenrod;
  color: #fff;
}

.contactIcon {
  height: 25px;
  width: 25px;
}

.contact .php-email-form {
  width: 100%;
  background: transparent;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  color: white;
  background: transparent;
  border: 1px solid goldenrod;
  font-weight: 600;
}

::placeholder {
  color: white !important;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: white;
  border: 2px solid goldenrod;
  padding: 10px 35px;
  color: goldenrod;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: goldenrod;
  color: white;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-center {
  width: 90%;
}

@media only screen and (max-width: 992px) {
  .contactWith {
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
  }

  .contactRow {
    flex-direction: column;
  }

  .contactRow1 {
    width: 90%;
    height: 200px;
    margin-left: 30px;
    /* border: 1px solid; */
  }

  .info {
    display: flex;
    justify-content: space-around;
    align-items: end;
    flex-wrap: wrap;
  }

  .contactRow2 {
    width: 100%;
    padding-left: 10%;
  }

  .inputMainDiv {
    display: flex;
    flex-direction: column;
  }

  .inputMainDiv div {
    width: 100%;
  }
}

@media only screen and (max-width: 453px) {
  .info>div {
    width: 60%;
  }
}

@media only screen and (max-width: 350px) {
  .info>div {
    width: 90%;
  }
}



/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footerMain {
  display: flex;
  justify-content: center;
  border-top: 2px solid goldenrod;
}

#footer {
  background: transparent;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#footer h3 {
  font-size: 36px;
  font-weight: 700;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0 0 15px 0;
  color: goldenrod;
  font-family: Georgia, serif;
	/* -webkit-text-stroke: 1px white; */
	text-shadow: 2px 2px 4px black;
}

#footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#footer .social-links {
  margin: 0 0 40px 0;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: white;
  color: goldenrod;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: goldenrod;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  margin: 0 0 5px 0;
}

#footer .credits {
  font-size: 13px;
}

.emailAndPhoneDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid; */
  margin-top: -15px;
}

.emailDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  height: 30px;
  width: 280px;
  cursor: pointer;
  /* border: 1px solid; */

}

.emailDiv:hover{
  color: goldenrod;
}


.phoneDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 30px;
  width: 150px;
  cursor: pointer;
  /* border: 1px solid; */
}

.phoneDiv:hover{
  color: goldenrod;
}



























/* about us  */

.mainDivSectionAbout {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.divRow {
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.aboutTextImg {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 50%;

}

.aboutTextImg>h3 {
  color: white;
  font-weight: 600;
  /* color: goldenrod; */
  /* font-family: Georgia, serif; */
	/* -webkit-text-stroke: 1px white; */
	text-shadow: 2px 2px 4px black;
}

.aboutTextImg>p {
  color: white;
  text-align: justify;
  /* font-family: Georgia, serif; */

}

.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About>p {
  color: white;
}

.aboutImgDiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: 30px;
  margin-top: 20px;
}

.aboutImgDivChild {
  display: flex;
  flex-direction: row;
  width: fit-content;
  /* border: 1px solid; */
}

.bord{
  align-items: end;
  display: flex;
  justify-content: end;
  border: none;
  border-left: 1px solid goldenrod;
  border-bottom: 1px solid goldenrod;

}

.borders{
  border: none;
  border-right: 1px solid goldenrod;
  border-bottom: 1px solid goldenrod;
}

.borders1{
  border: none;
  border-left: 1px solid goldenrod;
  border-top: 1px solid goldenrod; 
}

.bord2{
  border: none;
  border-top: 1px solid goldenrod; 
  border-right: 1px solid goldenrod;
}

.imgbig {
  height: 200px;
  width: 200px;
  margin: 10px;
}

.imgsmall {
  height: 150px;
  width: 150px;
  margin: 10px;

}

@media only screen and (max-width: 992px) {
  .divRow {
    flex-direction: column;
    align-items: center;
  }

  .aboutTextImg {
    width: 100%;
    margin-top: 20px;
  }

  .about {
    margin-left: 8%;
    margin-right: 8%;
    width: 84%;
  }

  .aboutImgDiv {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 470px) {
  .aboutImgDivChild {
    flex-direction: column;
  }

  .imgsmall {
    height: 300px;
    width: 350px;
    margin: 10px;
  }

  .imgbig {
    height: 300px;
    width: 350px;
    margin: 10px;
  }
}


@media only screen and (max-width: 500px) {
  .bord{
    border: none;
  }
  
  .borders{
    border: none;
  }
  
  .borders1{
    border: none;
  }
  
  .bord2{
    border: none;
  }

  .aboutImgDiv {
    margin-left: 0px;
    margin-top: 10px;
  }
}

/* amenities section css */

.amenitiesDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.amenitiesimgDiv {
  width: 30%;
  /* height: 200px; */
  margin-left: 30px;
}

.Rivers {
  width: 30%;
  margin-left: 0px;
  margin-right: 30px;
}

.amenitiesImages {
  height: 200px;
  width: 100%;
}

.amenitiesTextImgMainDiv {
  margin-bottom: 30px;
}


@media only screen and (max-width: 992px) {
  .skills {
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
  }

  .amenitiesDiv {
    flex-wrap: wrap;
  }

  .reversWrap {
    flex-wrap: wrap-reverse;
  }

  .Rivers {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-right: 0px;
    margin-top: 30px;
  }

  .amenitiesimgDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 30px;

  }

  .amenitiesImages {
    height: 60%;
  }

 
}




/* gallery images section */


.galleryImgRanderDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

}

.hightControl {
  /* border: 2px solid red; */
  height: 332px;

  width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 0px;
  /* height: 280px ; */
  /* margin-bottom: 50px; */
}


.galleryImgDiv {
  height: 350px;
  width: 350px;
  flex-wrap: wrap;
  /* border: 1px solid; */
}

.galleryImg{
  height: 350px;
  width: 400px;
}

@media only screen and (max-width: 1380px) {
  .hightControlHomePage {
    height: 350px;
    width: 350px;
  }

  /* .hightControl {
    height: 300px;
    width: 370px;
  } */
}

@media only screen and (max-width: 1280px) {
  /* .hightControlHomePage {
    height: 270px;
    width: 340px;
  } */

  /* .hightControl {
    height: 270px;
    width: 320px;
  } */
}

@media only screen and (max-width: 1130px) {
  /* .hightControlHomePage {
    height: 250px;
    width: 280px;
  } */

  /* .hightControl {
    height: 250px;
    width: 280px;
  } */
}

@media only screen and (max-width: 1030px) {
  /* .hightControlHomePage {
    height: 220px;
    width: 350px;
  } */

  /* .hightControl {
    height: 220px;
    width: 350px;
  } */

  .portfolio .portfolio-item {
    margin-bottom: 0px;
  }

  .galleryImg{
    height: 300px;
  }
  
}

@media only screen and (max-width: 857px) {
  .galleryImgRanderDiv {
    justify-content: center;
  }

  
  /* .hightControl {
    margin-left: 10px;
  } */

}

@media only screen and (max-width: 400px) {
  .hightControlHomePage {
    height: 320px;
    width: 320px;
  }
}









/* gallery section css */

.mainDivSeeMoreCount {
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: flex-end;
}

.galleryPageBtn {
  border: 2px solid white;
  color: goldenrod;
  font-size: 18px;
  width: 150px;
  height: 40px;
  border-radius: 15px;
}

.galleryPageBtn:hover {
  color: white;
  background: goldenrod;
  border: 2px solid goldenrod;

}

.amenitiesPageBtn {
  display: none;
  border: 2px solid white;.portfolio .portfolio-item {
    margin-bottom: 60px;
  }
  color: goldenrod;
  font-size: 18px;
  width: 150px;
  height: 40px;
  border-radius: 15px;
}

.amenitiesPageBtn:hover {
  color: white;
  background: goldenrod;
  border: 2px solid goldenrod;

}




/* video section btn css */



.slider-video-btn {
  margin: 2.5px;
  display: flex;
  flex-direction: row;
  border: 2px solid goldenrod;
  width: 150px;
  border-radius: 13px;
  margin-top: 100px;
  cursor: pointer;
}

.slider-book-now-btn {
  margin: 2.5px;
  border: 2px solid goldenrod;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  margin-top: 100px;
  cursor: pointer;
}

.btn-left-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.btn-right-text {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.slider-video-btn-text {
  color: white;
}

.sliderVideoMainDivChild{
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 992px){
  .sliderVideoMainDiv {
    display: flex;
    justify-content: center;
  }

}

@media only screen and (max-width: 312px){
  .sliderVideoMainDivChild{
    flex-direction: column;
  }

  .slider-book-now-btn {
    margin-top: 10px;
  }

}


.dailoBoxVideo {
  /* border: 2px solid; */
  width: 70%;
  height: fit-content;
  position: absolute;
  top: 5%;
  left: 15%;
  right: 15%;

}

.videoDiv {
  width: 1000px;
  height: 80%;
  /* border: 5px solid; */
}

.mainDivVideo {
  display: flex;
}

.closeBtn {
  /* display: none; */
  position: absolute;
  margin: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;

}


@media only screen and (max-width: 992px) {
  .mainDivVideo {
    display: block;
  }

  .closeBtn {
    /* display: none; */
    position: absolute;
    top: 0.5%;
    right: 1%;
    width: 30px;
    height: 30px;
    cursor: pointer;

  }

  .dailoBoxVideo {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 9999;
    background: transparent;
    backdrop-filter: blur(6px);

  }
}
