.dashboardMainDiv{
    display: flex;
    justify-content: center;
}

.galleryDashboardBtn{
    margin-top: 20%;
    padding: 10px;
    width: 100px;
    border-radius: 12px;
    color: white;
    background-color: goldenrod;
}


@media only screen and (max-width: 900px) {
    .dashboardMainDiv {
        display: none;
    }
}