.mainDivUploadCount{
    /* border: 1px solid; */
    display: flex;
    justify-content: flex-end;
    height: 100px;
    align-items: flex-end;
  }
  
  .galleryAdminPageUploadBtn{
    border: 2px solid white;
    color: goldenrod;
    font-size: 23px;
    width: 45px;
    text-align: center;
    height: 45px;
    border-radius: 40px;
    cursor: pointer;
  }
  
  .galleryAdminPageUploadBtn:hover{
    color: white;
    background: goldenrod;
    border: 2px solid goldenrod;
  
  }

  .editDeletIconMainDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60px;
  }

  .dashboardGalleryImgRander{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
  }



  /* dailog Modal */


  /* The Modal (background) */

.modal1 {
  /* display: block;  */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background-color: white; 
  background-color: white;  */
  
  animation: modal 0.3s linear;
}

@-webkit-keyframes modal {
  from {
      width: 0%;
      height: 0%;
  }

  to {
      width: 100%;
      height: 100%;
  }
}

@keyframes modal {
  from {
      width: 0%;
      height: 0%;
  }

  to {
      width: 100%;
      height: 100%;
  }
}


.modal-head{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalHeading {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
  position: relative;
  color: goldenrod;
}


/* Modal Content */
.modal-content {
  background: rgb(28, 41, 58);
  background: linear-gradient(90deg, rgba(28, 41, 58, 1) 0%, rgba(16, 84, 105, 1) 50%, rgba(0, 66, 85, 1) 100%);

  box-shadow: 0 0 20px #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: goldenrod;
  text-decoration: none;
  cursor: pointer;
}




.inputUpload{
  /* border: 2px solid white; */
  color: goldenrod;
  border-radius: 30px;
  padding: 0px;
  cursor: pointer;
  outline: none;
}

::-webkit-file-upload-button{
  background-color: goldenrod;
  color: #fff;
  padding: 13px;
  width: 150px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 30px;
  /* margin-right: 50px; */
  border: none;
}


.modalCloseSaveBtnDiv{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.inputText{
  color: goldenrod;
  font-size: 40;
  border-radius: 30px;
  padding-left: 20px;
}

.modalCloseSaveBtnDiv>button{
  border: 2px solid white;
  border-radius: 10px;
  color: goldenrod;
  width: 100px;
  height: 35px;
  margin-left: 20px;
}

.modalCloseSaveBtnDiv>button:hover{
  color: white;
  background-color: goldenrod;
  border: 2px solid goldenrod;

}


.modalFields>div{
  margin: 30px;
}

.surserChange{
  cursor: pointer;
}

.surserChange>i:hover{
  color: blue;
}

.deletIconDiv>i:hover{
  color: red;
}

.deletIcon{
  font-size: 24px;
}

.editIcon{
  font-size: 24px;
}


.flagMainDiv{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.visibility{
  font-size: 28;
  color: #fff;
  font-weight: 400;
  margin-top: 33px;
  margin-right: 10px;
}





.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  background-color: #121212;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: goldenrod;
  border: 1px solid goldenrod;

}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}







.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: gold;
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.signout_button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: red;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}



@media only screen and (max-width: 900px) {
  .gallery-dashboard {
      display: none;
  }
}